$disabled-color: #cccccc;
$disabled-cursor: text;
$ipad-min-width: 768px;
$ipad-max-width: 1024px;

.checkout {
  ol.checkout-progress {
    li {
      float: left;
      margin: 24px 24px 0;
      list-style-type: decimal;
      width: auto;
    }
  }
  .checkout__content {
    .checkout-buttons-container {
      text-align: right;
      margin-bottom: 30px;
    }
    .panel {
      .panel {
        header h2.checkout__panel-title {
          padding-top: 15px;
        }
      }
    }
  }
}

#recommended-products-panel {
  .recommended-product-items {
    .recommended-item {
      padding-bottom: 60px;
      .column.item_info {
        position: relative;
        .product_name {
          height: 45px;
        }
        .product_subname {
          height: 20px;
        }
      }
      .add_button {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

#shipping-info-panel,
#billing-info-panel {
  .address-container address {
    font-style: normal;
  }
}

.checkout .checkout__content {
  #viewcart-panel {
    @include viewcart-panel;
  }
  #bottom-viewcart-buttons {
    @include viewcart-panel;
  }
  .checkout-buttons-container input.disabled {
    @include disable-button();
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.tiny-waitlist-overlay h2 {
  margin: 30px 0 30px 0;
  text-align: left;
}

.tiny-waitlist-overlay .field {
  border-top: 1px solid gray;
  padding-top: 40px;
}

.tiny-waitlist-overlay .email_input {
  display: inline-block;
}

.tiny-waitlist-overlay .email_input input[type='text'] {
  width: 250px;
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field .align-r {
  display: inline-block;
  margin-left: 20px;
}

.waitlist_thankyou_message {
  font-family: 'Brandon Text Bold';
  margin: 130px 60px 90px 115px;
  line-height: 1.45em;
}

.billing {
  #checkout_complete .payment_method_container {
    .direct-debit .direct-debit-options a {
      border: 1px solid;
    }
  }
}

#billing-address-info-panel {
  #billing_address_form_container {
    border: 1px solid #000;
    padding: 20px;
    margin-top: 10px;
    .title_container {
      margin-bottom: 10px;
    }
  }
}

#recommended-products-panel {
  @media only screen and (max-device-width: $ipad-min-width) {
    .recommended-products-panel__content {
      padding: 15px;
      .recommended-product-items .recommended-item {
        .add_button input {
          font-size: 11px;
        }
      }
    }
  }
  @media only screen and (max-device-width: $ipad-max-width) {
    .recommended-products-panel__content {
      padding: 15px;
    }
  }
}

@media only screen and (max-device-width: $ipad-max-width) {
  #viewcart-panel {
    .viewcart-buttons-panel {
      a.continue-shopping {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-device-width: $ipad-min-width) {
  #viewcart-panel {
    .viewcart-buttons-panel {
      .continue-buttons {
        float: none;
        margin-bottom: 10px;
        a {
          width: 100%;
        }
      }
      a.continue-shopping {
        width: 100%;
      }
    }
  }
}

#order-summary-panel {
  .order-summary__content {
    .order-summary__total-label,
    .order-summary__total-value {
      padding-bottom: 0.4em;
    }
    .order-summary__tax-value {
      line-height: normal;
    }
  }
}

.loyalty-panels {
  .loyalty-panels__section {
    .samples {
      .product-list {
        .deactivate {
          pointer-events: none;
        }
      }
    }
  }
}

.viewcart {
  .cart_cross_sell_item {
    .cart-item__desc-info {
      .formatted_price {
        text-align: left;
        font-size: 14px !important;
        font-family: 'Brandon Text Bold';
        height: 40px;
        line-height: 24px;
        .legal_msg {
          float: right;
          letter-spacing: 0.001em;
          font-size: 12px !important;
          color: #cccccc !important;
        }
      }
    }
  }
}

.checkout-co-enabled {
  .order-summary-panel {
    &__title {
      margin-top: 10px;
    }
  }
}

.enhanced-delivery-page {
  .column.left {
    #checkout_complete {
      #payment-info-panel {
        .payment_method_container {
          .cc-header {
            display: none;
          }
        }
        #payment-type-cc .payment-type img {
          margin-left: -5px;
        }
      }
    }
  }
}