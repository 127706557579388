.address-overlay-shipping,
.address-overlay-billing {
  fieldset {
    .address-form__header {
      margin-left: 0px;
    }
    .address-form__content {
      .form_element {
        margin-bottom: 6px;
        input,
        select {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .title_container {
        margin-top: 6px;
      }
    }
  }
}

.newsletter-info {
  .sms_promotions {
    margin: 0 0 20px 20px;
  }
}

@media (min-width: 768px) {
  .site-nav {
    .menu__item--lvl-1 {
      vertical-align: top;
    }
  }
}

.sterling-nights {
  .product-collection-product__price {
    width: auto;
  }
  .product_smokey-mascara {
    .product-collection-product__price {
      width: auto;
    }
  }
}

.page-product {
  .product-full {
    .p-w-r {
      .pr-snippet-stars-reco-inline.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          @include breakpoint($portrait-up) {
            float: #{$rdirection};
          }
          .pr-snippet-review-count {
            margin-#{$rdirection}: 0;
            float: #{$rdirection};
          }
        }
        .pr-snippet-stars-container {
          @include breakpoint($portrait-up) {
            margin-#{$ldirection}: 5px;
          }
        }
      }
      &__size {
        color: $color-black-2;
      }
      &-price__price--unit-price {
        color: $color-warm-grey;
      }
    }
  }
}

.sticky-add-to-bag {
  &__info {
    .product-full-price__price {
      .legal_msg {
        display: block;
      }
    }
  }
}
