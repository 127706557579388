.enhanced-delivery-page {
  .ship-method-group {
    display: flex;
  }
  .pg_wrapper {
    .column.left {
      #checkout_complete {
        .wrapper {
          #payment-info-panel {
            .cc-header,
            #payment-type-ncc {
              border-top: 1px solid $color-nobel;
            }
            #payment-type-ncc {
              border-bottom: 1px solid $color-nobel;
              .payment-type {
                border-bottom: 1px solid $color-nobel;
              }
            }
            #payment-type-cc,
            #payment-type-ncc {
              .group2 {
                .payment-type {
                  border-bottom: none;
                }
              }
            }
          }
          .legal_txt {
            input[type='checkbox']:checked {
              &::before {
                #{$ldirection}: 5px;
                top: 0;
              }
            }
            label {
              bottom: 0;
            }
          }
        }
      }
      #checkout_shipping {
        .ship-method {
          &--title {
            margin-bottom: 22px;
          }
        }
        #billing_address_form_container {
          .address1_container {
            .address-form-name {
              display: block !important;
            }
          }
        }
        .billing-info {
          .billing-address-form-container {
            .address3_container,
            .state_container {
              display: none;
            }
          }
        }
      }
      .wrapper {
        color: $color-grey-dark;
        .shipping-title {
          margin-top: 28px;
        }
        #checkout_shipping {
          #billing-address-info-panel #bill_to_shipping_container {
            & ~ .address_controls {
              li .edit-billing-address {
                top: 70px;
              }
            }
            .billing-address {
              &.bill-form-expand {
                & ~ #billing_address_form_container {
                  .billing-header {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .gift_container dd {
      color: $color-grey-dark;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.25px;
      line-height: 1.67;
      text-transform: uppercase;
    }
  }
  &#payment {
    .checkout__sidebar {
      .shopping-bag-panel__title {
        .edit-icon {
          margin-#{$rdirection}: 10px;
        }
      }
    }
  }
}
