.account-page {
  & &__non__loyalty__container {
    height: auto;
    background-position: bottom;
    @if ($lr2021) {
      background-size: contain;
      background-image: url('/media/export/cms/loyalty/account_loyalty_banner_pc_bg_lr2021_vertical.jpg');
      background-color: $color-black;
      padding-bottom: 90%;
    } @else {
      background-size: cover;
      background-color: $color-gray;
    }
  }
  & &__non__loyalty__loyalty__text {
    width: 100%;
  }
  & &__non__loyalty__checkbox {
    .label-content {
      width: auto;
      display: inline;
    }
  }
}

.tier-1,
.tier-2 {
  @include breakpoint($medium-up) {
    .loyalty__panel__points__info-section {
      .loyalty__panel__points__info-header {
        margin-bottom: 49px;
      }
    }
    .loyalty__panel__points__info-section.next-tier {
      .loyalty__panel__points__info-header {
        margin-bottom: 30px;
      }
    }
  }
}

.info-content {
  margin: 31px 0 45px;
  position: relative;
  .info-link-message {
    #{$ldirection}: -1px;
    background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat center;
    background-size: 100%;
    cursor: pointer;
    display: block;
    height: 20px;
    position: absolute;
    text-align: $ldirection;
    text-indent: -9999px;
    width: 20px;
  }
  .info-link {
    @include swap_direction(padding, 2px 0 0 30px);
    color: $color-black;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.25px;
    line-height: 1.1;
    margin: 0;
    position: relative;
    text-decoration: underline;
    text-transform: uppercase;
    top: 2px;
  }
}

.welcome15-overlay {
  #cboxClose {
    @include breakpoint($medium-down) {
      top: 15px;
      right: 15px;
    }
  }
  .welcome-overlay {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .welcome-overlay__header {
    margin-right: 15px;
  }
  .welcome-overlay__copy {
    margin-bottom: 15px !important;
    a {
      color: $color-gray-dark;
    }
  }
  .welcome-overlay__label {
    margin-top: 50px;
    @include breakpoint($medium-up) {
      margin-top: 110px;
    }
    .welcome-overlay__label__overlay.visible {
      font-size: 9px;
      z-index: 999;
      @include breakpoint($medium-up) {
        font-size: 11px;
      }
    }
  }
  .welcome-overlay__email {
    .form-text {
      margin-bottom: 15px !important;
    }
  }
  .welcome-overlay__submit__button {
    @include breakpoint($medium-down) {
      margin: 0 0 15px !important;
    }
  }
  .welcome-overlay__footer {
    &__link {
      margin-top: 0 !important;
    }
  }
}

.loyalty-popup {
  @include breakpoint($medium-down) {
    &__content {
      width: 90% !important;
    }
    .loyalty-email-signup {
      .email-submit {
        max-width: 135px !important;
        padding: 0;
      }
      .loyalty-terms {
        text-align: left;
      }
    }
  }
  @include breakpoint($medium-up) {
    min-width: 610px !important;
    &__content {
      padding: 28px 15px 4px !important;
    }
    .loyalty-email-signup {
      .email-submit {
        max-width: 100% !important;
      }
      .loyalty-additional-info {
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
  }
}

#loyalty__panel__points-instruction {
  .loyalty__panel__points-less-content {
    display: none;
  }
  .loyalty__panel__points-instruction-show-more,
  .loyalty__panel__points-instruction-show-less {
    cursor: pointer;
  }
}

.loyalty-page__content {
  .loyalty__panel__points__info-link {
    .info-link--hyper-link {
      display: block;
    }
  }
}

#loyalty-offer-code-panel {
  margin-top: 15px;
  border-bottom: 1px solid $color-black;
  .offer-code-wallet__code {
    &-info--applied {
      width: 200px;
      margin: auto;
    }
    &__description {
      text-align: center;
      margin-top: 5px;
      &-description {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .loyalty-offer-panel__title {
    border: none;
    padding: 0 25px;
    &.open {
      .title-icon {
        @include icon('arrow_down', 'after', false, false, 14px);
        transform: rotate(180deg);
      }
    }
  }
  .title-icon {
    @include title-icon;
    #{$rdirection}: 20px;
    @include breakpoint($medium-up) {
      #{$rdirection}: -5px;
    }
    &::after {
      font-weight: bold;
    }
  }
  .loyalty-points-content {
    &__summary__non-member-content {
      font-weight: 700;
      text-align: center;
      display: inline-block;
      margin-bottom: 20px;
      width: 80%;
    }
    &__summary__member-content {
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .loyalty-offer-points-panel__content {
    text-align: center;
  }
  .loyalty-info-icon {
    position: relative;
    font-size: 9px;
    border: 1px solid $color-gray-dark;
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 1.4;
  }
  .loyalty-tooltip-content {
    display: none;
  }
}

#loyalty_join {
  .account-page__non__loyalty__checkbox {
    .col-60 {
      width: 100%;
      input[type='text'] {
        margin-top: 10px;
        width: 50%;
      }
    }
  }
  .loyalty-membership-section__join-now {
    margin-#{$ldirection}: -20px;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 0;
    }
  }
}

.new-account__item {
  &__communication_sms_block & {
    &_phone1 input {
      margin: 0;
    }
  }
}

.clearfix {
  .loyalty__panel__points__info-rewards-container-link {
    display: none;
  }
}

.account {
  .account__section {
    .account-mobile__non__loyalty__join__link.button--disabled {
      background: $color-gray;
    }
  }
}

.account-section__content.loyalty-page__content {
  #loyalty__panel__earnpoints-landing {
    .content {
      text-align: center;
      .earning-points {
        &__header {
          font-size: 20px !important;
        }
        &__subheader {
          font-size: 16px;
        }
      }
      .earning-points__section--redesign2021 {
        .earning-points__data__footer {
          width: 90%;
        }
      }
    }
  }
}

#order_status {
  .checkout-panel.checkout-panel--loyalty-points {
    display: none;
  }
}

.loyalty__panel__points__info-section.next-tier {
  .loyalty__panel__points__points-number {
    display: inline-block;
    padding-#{$right}: 5px;
  }
}

.loyalty__panel__offers {
  &__title {
    font-size: 20px;
  }
}

.loyalty__panel__offers__sku {
  &__title {
    font-size: 15px;
  }
  &__list {
    &-container {
      .tabbed-rewards-block {
        &__tabs {
          @include breakpoint($medium-down) {
            display: flex;
          }
        }
      }
    }
  }
}

.loyalty__panel__transactions {
  &__transaction {
    .mobile-hidden {
      @include breakpoint($medium-up) {
        display: block !important;
      }
    }
  }
  &__title {
    font-size: 15px;
  }
  &__table {
    font-size: 15px;
  }
  &__list__column-activity {
    width: 40%;
    &__transaction-subtype {
      display: block;
    }
  }
  &__subtitle {
    font-size: 14px;
    @include breakpoint($medium-up) {
      font-size: 16px;
    }
  }
}

.opc__confirm {
  .confirmation-panel {
    input[type='submit'] {
      background-color: $color-black;
      &:hover {
        background-color: $color-pink;
      }
    }
  }
}

.marketing__wrapper--redesign2021 .marketing__enrollment {
  @include breakpoint($medium-up) {
    &__header {
      @media screen and (min-resolution: 125dpi) {
        font-size: 60px;
      }
    }
    &__subheader {
      @media screen and (min-resolution: 125dpi) {
        width: 100%;
      }
    }
    &__signup-section {
      .marketing__enrollment__email {
        @media screen and (min-resolution: 125dpi) {
          width: 69%;
        }
      }
      .marketing__enrollment__button {
        @media screen and (min-resolution: 125dpi) {
          padding-#{$ldirection}: 5px;
          padding-#{$rdirection}: 5px;
          width: 28%;
          font-size: 10px;
        }
      }
    }
  }
}
