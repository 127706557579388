.account-loyalty {
  display: block;
  width: 100%;
  background: $color-gray-light-alt;
  div.section-content {
    text-align: center;
  }
  a {
    display: block;
    text-decoration: none;
  }
  span {
    font-weight: bold;
  }
}
