.checkout {
  .checkout__sidebar {
    section {
      margin-bottom: 10px;
      background: #eff0f0;
      padding: 16px 20px;
      p {
        margin-bottom: 15px;
      }
    }
    .account-utilities__section {
      h3.account-utilities__header {
        font-family: 'Brandon Text Bold';
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid #000000;
        text-transform: uppercase;
      }
    }
    #links-panel {
      ul.links_list {
        li {
          border: 0px;
          margin-bottom: 10px;
        }
      }
    }
    #offer-code-panel {
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code .offer_code_form_container {
          #one-offer-only {
            display: block;
            margin: 10px 0px;
          }
        }
      }
    }
  }
  .checkout__content {
    #viewcart-panel {
      .viewcart-panel__content {
        .total_column.sub__total {
          text-align: right;
          margin: 10px 0px;
        }
      }
    }
    #shipping-panel {
      .transaction-panel-details {
        margin-top: 20px;
        .transaction-item {
          float: left;
          margin: 0 28px 28px 0;
          position: relative;
          width: 275px;
          word-wrap: break-word;
        }
      }
    }
    #review-instructions {
      padding: 15px 0px;
      p {
        margin: 0px;
      }
    }
  }
  .below-columns {
    #recommended-products-panel {
      .recommended-products-panel__content .recommended-product-items .recommended-item {
        .description {
          margin: 10px 0px;
          .product_name {
            min-height: inherit;
          }
          div {
            margin: 0px 0px 10px 0px;
          }
          .color .swatch {
            margin-right: 10px;
          }
        }
      }
    }
  }
  ol.checkout-progress li {
    width: 175px;
  }
  .samples-buttons {
    &.top {
      padding-bottom: 20px;
    }
  }
}

#samples-panel #checkout_samples {
  .samples-buttons.bottom {
    display: block;
  }
}

.shipping {
  .checkout-progress li.checkout-progress__shipping {
    font-weight: bold;
  }
  .checkout__content {
    #shipping-page-info-panel #checkout_shipping {
      section {
        margin-bottom: 10px;
      }
      #shipping-address-info-panel {
        #address_form_container .address {
          .address-form__content {
            .title_container {
              margin-bottom: 10px;
            }
          }
        }
        #shipping-info-panel {
          padding: 0px;
          .shipping-address {
            margin-bottom: 10px;
          }
          ul.address_controls li {
            margin-bottom: 10px;
            &#choose-address select {
              margin-top: 5px;
            }
          }
        }
      }
      #billing-address-info-panel #billing-info-panel {
        padding: 0px;
        .billing-address {
          margin-bottom: 10px;
        }
      }
      #delivery-info-panel #delivery-info-content-panel {
        padding: 0px;
        #delivery-options {
          p {
            margin-bottom: 10px;
          }
        }
      }
      #emailpromo-info-panel .newsletter-info {
        padding: 0px;
        h3.newsletter-info__header {
          text-indent: 0px;
        }
        p {
          margin: 0px 0px 15px 0px;
        }
        .newsletter-info__item {
          margin-left: 0px;
          padding-left: 0px;
        }
        .sms_promotions {
          margin-left: 0px;
        }
      }
    }
  }
}

.review {
  .checkout-progress li.checkout-progress__review {
    font-weight: bold;
  }
  .checkout__content {
    #checkout_review {
      .promotions-container {
        h3.newsletter-info__header {
          text-indent: 0px;
        }
      }
      p {
        margin: 0px 0px 15px 0px;
      }
      .newsletter-info__item {
        margin-left: 0px;
        padding-left: 0px;
      }
      .sms_promotions {
        margin-left: 0px;
      }
    }
  }
}

.billing {
  .checkout-progress li.checkout-progress__billing {
    font-weight: bold;
  }
  #checkout_complete {
    #payment-info-panel {
      .payment_method_container {
        margin-bottom: 15px;
        p {
          margin: 10px 0px;
        }
        div {
          margin-bottom: 10px;
          &.direct-debit .direct-debit-nickname input {
            margin-bottom: 0px;
          }
        }
        .payment-type {
          label {
            display: block;
            overflow: hidden;
            height: 60px;
            padding: 20px 0px;
            img {
              position: absolute;
              margin-top: -7px;
              left: 30px;
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label {
              img {
                width: 60px;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label {
              img {
                margin-top: -20px;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label {
              img {
                margin-top: -20px;
              }
            }
          }
        }
      }
    }
  }
  #promo-message {
    margin-bottom: 15px;
  }
}

.confirm {
  .checkout-progress li.checkout-progress__confirm {
    font-weight: bold;
  }
  .checkout__content {
    #confirm-panel {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .cart-item {
    margin-top: 20px;
  }
  .cart-item__thumb {
    width: 13%;
    margin-right: 10px;
  }
  .cart-item__desc {
    float: left;
    width: 30%;
    margin-left: 10px;
  }
  .cart-item__price {
    text-align: center;
  }
  .cart-item__qty {
    float: left;
    clear: none;
    padding-top: 0;
    margin: 0 10px;
    width: 14%;
    text-align: center;
  }
  .cart-item__total {
    width: 14%;
    float: left;
    line-height: 36px;
    text-align: right;
  }
  .order-totals {
    float: right;
    table {
      th,
      td {
        border: 0px;
      }
    }
  }
}

.section-store-locator.device-pc {
  .resp-tabs-list {
    li:first-child {
      width: 60%;
    }
    li:last-child {
      width: 40%;
    }
  }
  .store-locator__main {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      letter-spacing: 1px;
      padding: 1em 5px;
      margin: 0 1% 0 0;
    }
    .customer-service-contact-form__category#category-products {
      letter-spacing: 0px;
      padding: 25px 2px 0px 2px;
      width: 26%;
    }
    .customer-service-contact-form__category#category-service {
      width: 25%;
    }
    .customer-service-contact-form__section {
      #category-online-content {
        .customer-service-contact-form__radio-item label::before {
          font-size: 13px;
          margin-top: 1px;
        }
        .customer-service-contact-form__radio-item label {
          .orderstatus_exchanges_radiobutton {
            display: inline-block;
            margin-top: 1px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.customer-service-landing {
  .customer-service-section {
    div#customer_service_chat {
      display: block;
    }
    #landing-chat {
      #customer_service_chat {
        a {
          top: 20px;
        }
      }
    }
  }
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

.gtl__steps__content {
  .gtl__steps__body p {
    width: 90%;
  }
}

.lp_radio_button,
.lp_checkbox_button {
  input {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        letter-spacing: 0px;
        padding: 1em 5px;
        word-wrap: break-word;
      }
      .customer-service-contact-form__category#category-products {
        letter-spacing: 0px;
        padding: 1em 5px;
      }
    }
  }
  .customer-service-menu {
    a.menu__link--lvl-1 {
      width: auto;
    }
  }
  .telluride-collection {
    .lw-gel,
    .text-block {
      height: 430px;
    }
    .smokey-eye {
      .product-collection-product {
        bottom: 0;
      }
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
    .store-locator__link-events {
      float: right;
      margin: 5px 0 0 20px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .section-store-locator {
    .store-locator__form-container {
      #store-search-controls {
        display: block;
      }
    }
    .store-locator__main .responsive-tabs {
      .resp-tabs-list {
        li:first-child {
          width: 64%;
          letter-spacing: 0.05em;
        }
        li:last-child {
          width: 36%;
          letter-spacing: 0.05em;
        }
      }
    }
  }
}

.sign-in.is_signed_in {
  width: 320px;
}

.elc-user-state-anonymous {
  .site-email-sms-signup {
    padding-top: 15px;
    .site-email-sms-signup__header {
      h1 {
        font-size: 2em;
        letter-spacing: 2px;
      }
      h2 {
        font-size: 1.4em;
        letter-spacing: 0;
        margin-bottom: 10px;
      }
    }
    .site-email-sms-signup__agree {
      span.label-content {
        letter-spacing: 0.9px;
      }
      input#form--email_signup--field--SMSPROMO_SMS_PROMOTIONS {
        display: none;
      }
    }
  }
}

.samples-page {
  .product {
    min-height: 482px;
    width: 21.6%;
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.section-esearch {
  #perlgem-search-form {
    .search-form__submit {
      letter-spacing: 0.1em;
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
  #search-wrapper {
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        #product-results {
          margin-#{$ldirection}: 0;
          .results-header {
            margin-#{$ldirection}: 20px;
          }
          .product-grid__content {
            max-width: 1440px;
            display: flex;
            flex-wrap: wrap;
            .product-grid__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}

.site-utils__dropdown--account {
  .sign-in {
    .registration form input {
      width: 100%;
    }
    .registration__password-container {
      overflow: hidden;
    }
    .registration__show-pass {
      margin: 5px 0 0 0;
    }
    .registration__show-pass-text {
      letter-spacing: 0em;
    }
    .registration__terms {
      margin-top: 10px;
    }
    .registration__email-list {
      margin: 10px 0 18px 0;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .site-footer {
    .site-footer-contact {
      .customerservice_width {
        width: auto;
        .site-footer-contact__link {
          margin-left: 1%;
        }
      }
    }
    .site-email-signup {
      &__field {
        width: 53%;
      }
      &__submit {
        height: 32px;
        letter-spacing: 1px;
        width: 44%;
        padding: 0px;
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .site-footer {
    .site-footer-contact {
      .customerservice_width {
        .site-footer-contact__link {
          margin-left: 10%;
        }
      }
    }
    .site-email-signup {
      .site-email-signup__submit {
        height: 32px;
      }
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .quickshop {
    .product__details .product__actions {
      .product__full-details-link-wrapper {
        float: none;
        margin-bottom: 10px;
      }
      .product__notify-me {
        margin-top: 10px;
      }
    }
  }
}

.site-header__addtobag {
  .site-header__addtobag-cell {
    .site-header__addtobag__product-info {
      div {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        div {
          font-size: 12px;
        }
      }
    }
    .site-header__addtobag-button {
      a {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service {
    .customer-service-section {
      .customer-service-landing-block {
        height: 325px;
        .customer-service-landing-block__link {
          bottom: 15px;
        }
      }
    }
  }
  .device-pc {
    .bobbis-lessons {
      &__footer {
        margin: 0 2% 0 2%;
      }
    }
    .makeup-lessons-item {
      &__wrap {
        padding: 30px 0 25px 0;
      }
    }
    .makeup-lessons-items {
      &__lessons {
        bottom: 5px;
        width: auto;
        .makeup-lessons-item {
          margin: 9px 9px 9px 0px;
        }
      }
    }
  }
  .site-nav {
    .menu__item--lvl-2 {
      padding-right: 25px;
      word-wrap: break-word;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .spp {
    .responsive-tabs--how_to_use-wrap .resp-tab-item {
      padding: 8px 15px;
    }
  }
}

.site-footer .site-footer-contact ul {
  li.site-footer-contact__item {
    width: 50%;
    a {
      white-space: pre-wrap;
      span {
        margin-right: 10px;
      }
    }
  }
}

.ie8 {
  .section-store-locator {
    .store-locator--desktop {
      .store-locator__locate.geo_search,
      .store-locator-either-or {
        display: none !important;
      }
    }
  }
  .sign-in {
    .account-links .account-links__container {
      li.account-links__orders {
        height: auto !important;
      }
    }
  }
  .makeup-lessons-item__thumb-image {
    max-width: none;
  }
}

.customer-service-menu {
  a.menu__link--lvl-1 {
    height: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .past-purchases__content {
    .past-purchases-data-header__item,
    .past-purchases-data-item__item {
      letter-spacing: 0;
    }
    .past-purchases-data-header__item.created-date,
    .past-purchases-data-item__item.created-date {
      width: 20%;
    }
    .past-purchases-data-header__item.trans-id,
    .past-purchases-data-item__item.trans-id {
      width: 31%;
    }
  }
  .offers-hero {
    .offers-hero__actions {
      .offers-hero__button {
        margin: 15px 0 0 15px;
      }
    }
  }
  .offers-details {
    .offers-details-item__button {
      margin: 13px 0 0 25px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .offers-hero {
    .offers-hero__actions {
      .offers-hero__button {
        margin: 15px 0 0 15px;
      }
    }
  }
}

.site-utils__menu {
  .site-utils__item--email {
    .site-utils__dropdown--email {
      right: 100px;
    }
  }
}

.bobbis-lessons__footer {
  a.link--get-the-look {
    padding-right: 60px;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 50%;
  }
  .bobbis-lessons__footer--right a {
    padding: 0px;
  }
}

@media only screen and (max-device-width: 768px) {
  .makeup-lessons__header--content .makeup-lessons__header--link {
    padding: 5px 2px;
  }
  a.makeup-lessons__header {
    background-size: 650px 424px;
  }
  div.makeup-lessons__header {
    background-size: 480px 328px;
  }
}

@media only screen and (max-device-width: 768px) {
  .site-footer .site-footer-contact ul {
    li.site-footer-contact__item--phone {
      float: none;
      width: 100%;
      text-align: center;
    }
    li.site-footer-contact__item--email {
      width: 100%;
      text-align: center;
    }
  }
}

.mpp.gift_guide {
  .product-filter {
    width: 900px !important;
  }
}

.lw-collection {
  .button--outline {
    width: 195px !important;
  }
}

.bobbi-glow {
  .product_highlighting-powder {
    min-height: 345px;
    background-position: center 20px;
    padding: 350px 30px 30px 0;
    border-top: 1px solid #eff0f0;
    border-bottom: 1px solid #eff0f0;
  }
}

.product-grid__content {
  .product-grid__item {
    .product-thumb {
      .product-thumb__abstract {
        .product-thumb--price {
          .legal_msg {
            float: none;
            letter-spacing: 0.001em;
          }
        }
      }
    }
  }
}

.js-popup-offer-overlay-cbox.popup-offer-cbox {
  .popup-offer {
    .popup-offer__outer-wrap.popup-offer__sign-up {
      padding: 15px;
      h2 {
        margin-bottom: 20px;
      }
      .popup-offer__body {
        p {
          margin: 0 0 25px 0;
        }
      }
      .popup-offer__footer {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

.product_collection_hero__content {
  .product-grid__content {
    .product-thumb {
      padding: 0 15px 20px;
    }
  }
}

.secure_popup.submit {
  text-align: center;
  padding: 17px 0 0px 0;
  .button-image {
    height: 30px;
    width: 137px;
    background: #ff4661;
    color: #ffffff;
    display: inline-block;
    padding: 4px;
    cursor: pointer;
  }
}

#payment {
  #cboxLoadedContent {
    overflow: hidden !important;
  }
}

.secure_message {
  font-family: Brandon Text Regular;
  font-size: 15px;
  padding: 6px 15px 0px 15px;
  text-align: center;
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          letter-spacing: 1px;
          font-style: normal;
        }
      }
    }
  }
}

#cboxContent {
  .offers-everyday {
    .offers-everyday__items {
      .offers-everyday__item:last-child {
        width: 29% !important;
      }
      .offers-everyday__item:nth-child(2) {
        width: 34% !important;
      }
      .offers-everyday__item:nth-child(1) {
        width: 37% !important;
      }
      .offers-everyday__item-inner {
        padding: 0px;
      }
    }
  }
}

.product-grid-products {
  .product-thumb--price {
    span.legal_msg {
      font-size: 11px;
    }
  }
}

.skin__foundation__dropdown__select__container {
  .skin__foundation__dropdown__select__copy {
    width: 79%;
  }
}

.skin__foundation__menu.skin__foundation__menu--01 {
  width: 71.333% !important;
  left: -139px !important;
  cursor: context-menu !important;
}

.skin__foundation__menu.skin__foundation__menu--02 {
  display: none !important;
}

.skin__foundation__menu.skin__foundation__menu--03 {
  display: none !important;
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Ja';
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'Nein';
            }
          }
        }
      }
    }
  }
}

#pr-war-form {
  .tag-group {
    .input-add-tag {
      width: 84%;
    }
  }
  .pr-hautbedürfnisse-form-group,
  .pr-produktvorteile-form-group {
    .pr-control-label {
      float: left !important;
      margin-right: 20px;
    }
  }
}

.site-footer {
  &__column {
    .site-footer__country-chooser {
      a.country-select__selectbox {
        margin-bottom: 25px;
        &:hover {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.slick-cloned:nth-last-child(1) {
  .position-image {
    .content-block__picture {
      img {
        display: none;
      }
    }
  }
}
