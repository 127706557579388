.change-password {
  .password-field {
    &__info {
      &-reset {
        @include breakpoint($medium-up) {
          top: 0;
          left: 0;
          width: 190px;
          transform: translate(98%, -37%);
        }
      }
    }
  }
}
